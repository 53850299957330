import React from 'react';

const Footer = () => {
  return (
    <p className='bg-primary text-center text-white py-2 mt-8'>
      Diana Deluvian, All Rights Reserved
    </p>
  );
};

export default Footer;
